import React from "react";
import { useEffect, useState } from "react";
import "../assets/css/promos.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useFetch } from "../hooks/useFetch";

const Destino = "Promoções";

const Promos = () => {
  const [promos, setPromos] = useState([]);
  useEffect(() => {
    document.title = Destino + " - Agaxtur Viagens";
  }, []);

  const { data: Pacotes } = useFetch(`${process.env.REACT_APP_API_URL}/api/pacotes/`);

  useEffect(() => {
    Pacotes.length > 0 && setPromos(Pacotes.filter((pacote) => pacote.promocao === true));
  }, [Pacotes]);

  const formatedDate = (date) => {
    let getdate = new Date(date)
    return getdate.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
  }

  return (
    <div className="py-3 mt-4">
      <div className="row mb-4">
        {promos.length > 0 && promos.map((promos, key) => {
          return (
            <div className="col-lg-4 col-sm-12 mb-4">
              <div className="card card-span card-height" key={key}>
                <LazyLoadImage
                  src={`https://agaxtur-uploads.s3.amazonaws.com/${promos.imageUrl}`}
                  className="card-img-top promocoes-pacote-image"
                  alt={promos.title}
                />
                <span className="title">{promos.title}</span>
                <span className="noites">{promos.noites} Noites</span>
                <span className="descricao">{promos.description}</span>
                <span className="valorTotal">
                  a partir de{" "}
                  <span className="valorTotalDestaque">
                    {promos.valorTotal ? promos.valorTotal : (promos.valor * 10).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                  </span>
                </span>
                <span className="valorParcela">
                  ou{" "}
                  <span className="valorParcelaDestaque" style={{marginLeft: 5}}>
                    {promos.price ? promos.price : `10x 
                      ${promos.valor.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}`}
                  </span>
                </span>
                <span>
                  {promos.dataReferencia && formatedDate(promos.dataReferencia)}
                </span>
                <a
                  href={"https://agaxtur.travellink.com.br/"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="promos-btnAction"
                >
                  Fale agora com um consultor!
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Promos;
