import { 
    SET_FLYER, 
    SET_BANNER,
    SET_PAGES_B,
    SET_PAGES_T,
    SET_TEXTS,
    SET_RECLAME_AQUI
} from './extras/extrasReducer';
import { SET_TRAVEL_P, SET_TRAVEL_C, SET_TRAVEL_M, SET_TRAVEL_S } from './travel/travelReducer';
import { SET_CRUISES_P, SET_CRUISES_C, SET_CRUISES_S } from './cruises/cruisesReducer';
import { SET_TEAM_M, SET_TEAM_L, SET_TEAM_C } from './team/teamReducer';

export default function useReducerActions() {
    return {
        SET_BANNER, 
        SET_TRAVEL_P,
        SET_TRAVEL_C,
        SET_CRUISES_P,
        SET_CRUISES_C,
        SET_PAGES_T,
        SET_PAGES_B,
        SET_TEXTS,
        SET_FLYER, 
        SET_TRAVEL_M,
        SET_TRAVEL_S,
        SET_CRUISES_S,
        SET_TEAM_M,
        SET_TEAM_L,
        SET_TEAM_C,
        SET_RECLAME_AQUI
    };
};