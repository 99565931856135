import React from "react";
import imageSAC from "../images/sac-icon.png";
import useDimensions from "../hooks/useDimensions";

function WhatsApp() {
  const { dimensions } = useDimensions();
  const containerStyle = {
    position: "fixed",
    bottom: "0",
    right: dimensions.scroll > 50 ? "0" : "-200px",
    transition: "right 300ms ease-in-out",
  };

  return (
    <>
      <div className="fixed-bottom float-end" style={containerStyle}>
        <a
          href="https://wa.me/5511982270144?text=Ol%C3%A1,%20Gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20viagens"
          target="_blank"
          rel="noreferrer"
          className="rounded-circle whatsapp sac-icon"
          aria-label="Link para falar com um consultor pelo Whatsapp">
          <img src={imageSAC} width={65} height={65} alt="sac-icon" className="mx-auto d-block" />
        </a>
      </div>
    </>
  );
}
export default WhatsApp;
