import React, { useEffect, useState } from "react";
import FormularioSharp from "../components/FormularioSharp";
import { useParams } from "react-router-dom";
import PacoteCard from "../components/home/PacoteCard/index.jsx";
import SkeletonCard from "../components/SkeletonCard";
import { useSelector } from "react-redux";

const Destinos = () => {
  const state = useSelector((state) => state);
  const { travel_c, travel_p } = state.travel
  const { slug } = useParams();
  const [destino, setDestino] = useState({});

  useEffect(() => {
    if (travel_c.length > 0) {
      travel_c.filter((cat) => cat.slug === slug).map((cat) => setDestino(cat));
    }
    if (destino.title) {
      document.title = `${destino.title} - Agaxtur Viagens`;
    }
  }, [slug, destino, travel_c]);

  return (
    <>
      <div className="destinos">
        <header
          className="text-center"
          style={{
            backgroundImage: `url(https://agaxtur-uploads.s3.amazonaws.com/${destino.imageUrl})`,
          }}
        >
          <h1>{destino.title}</h1>
          <h3>
            <span className="badge bg-secondary">
              {destino.headline}
            </span>
          </h3>
        </header>
        <div className="container">
          <div id="pacotes" className="py-3 mt-4">
            {destino.description ? (
              <div className="row pb-4">
                <div className="col-12">
                  <h2 className="mb-3">
                    Sobre <span className="azul-claro">{destino.title}</span>
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: `${destino.description}` }}
                  ></div>
                </div>
              </div>
            ) : null}

            <h2 className="mb-4 text-center">
              {destino.subtitulo}
            </h2>

            {travel_p.length > 0 ?
              <div className="row mb-4">
                {travel_p.filter(
                  (pacote) => pacote.destino && pacote.destino._id === destino._id
                ).map(
                  (pacote) => {
                    return (
                      <PacoteCard pacote={pacote} showButton={true} key={pacote._id} />
                    );
                  }
                )}
              </div>
              :
              <div className="row mb-4">
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
              </div>
            }
          </div>
        </div>
      </div>
      <FormularioSharp />
    </>
  );
};

export default Destinos;