import React from "react";
import PacoteCard from "./PacoteCard/index.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import SkeletonCard from "../SkeletonCard/index.jsx";
import { useSelector } from "react-redux";

const Exoticos = ({ data }) => {
  const state = useSelector((state) => state);
  const { texts } = state.extras;

  // Esta sessão da página home, exibe "circuitos" devido a solicitações da agaxtur. 02/08/2023 
  return (
    <section className="py-7 overflow-hidden grade-imagens" id="circuitos">
      <div className="container bg-offcanvas-gray-right">
        <div className="row gx-2">
          <div className="col-12 col-md-12 col-lg-3 mb-2">
            <div className="gradient-base-dark-blue gradient-row h-100 d-flex align-items-center">
              <div className="row g-0">
                <div className="col-12">
                  <div className="p-lg-4 px-2 py-3 px-md-3 text-light">
                    <h2 className="my-2 text-light"> <FontAwesomeIcon icon={solid('bus')} size="1x" color="#FFF" style={{marginRight: 10}}/> 
                    {texts && texts.filter((titulo) => titulo._id === "644a98adf3291aa9d5a21fbc")[0].title}
                    </h2>
                    <p>
                    {texts && texts.filter((titulo) => titulo._id === "644a98adf3291aa9d5a21fbc")[0].texto}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            data ?
              data.filter(
                (pacote) =>
                  pacote.featured === true && pacote.local === "circuitos"
              ).map((pacote) => {
                return <PacoteCard pacote={pacote} circuito={true} key={pacote._id} />;
              })
              :
              <>
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
              </>
          }
        </div>
      </div>
    </section>
  );
};

export default Exoticos;
