import React from "react";
import './styles.css'

const SkeletonCard = () => {
    // const buttonVisible = showButton !== undefined ? showButton : false;

    return (
        <div className="col-6 col-md-3 col-lg-3 mb-2 skeleton-container"
            id="geral-pacote-card-container"
            style={{ cursor: 'pointer' }}
        >
            <div className="pacote-box h-100">
                <div className="pacote-img" id="skeleton-image">
                    {/* imagem do card  */}
                </div>
                <div className="pacote-detalhes p-3" >
                    <h2 className="fs-1 fs-md-2" id="skeleton-title">Titulo do card</h2>
                    <div className="pacote-descricao">
                        <span id="skeleton-description">
                            Descrição do Card
                            Descrição do Card
                            Descrição do Card
                            Descrição do Card
                            Descrição do Card
                        </span>
                    </div>
                    <div>
                        <div>
                            <small id="skeleton-price-title" className="pacote-texto-valor" style={{ color: "transparent" }}>a partir de:</small> <br />
                            <h3 id="skeleton-small-price">10 x R$265</h3>
                        </div>
                        <div className="pactote-texto-total">
                            <span id="skeleton-price" >
                                R$11455
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonCard;
