import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    team_m: [],
    team_l: [],
    team_c: [],
};

export const teamSlice = createSlice({
    name: "team",
    initialState,
    reducers: {
        SET_TEAM_M: (state, action) => {
            state.team_m = action.payload;
        },
        SET_TEAM_L: (state, action) => {
            state.team_l = action.payload;
        },
        SET_TEAM_C: (state, action) => {
            state.team_c = action.payload;
        }
    },
});

export const {
    SET_TEAM_M,
    team_m,
    SET_TEAM_L,
    team_l,
    SET_TEAM_C,
    team_c,
} = teamSlice.actions;

export default teamSlice.reducer;
