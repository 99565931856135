import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useSelector } from "react-redux";
import useReducerActions from "../reducer";
import { useDispatch } from "react-redux";
import axios from "axios";

export default function TimeVendas() {
  const emailURL = "mailto:";
  const [selectedLocalizacao, setSelectedLocalizacao] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAgent, setSelectedAgent] = useState("");
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const {team_m, team_l, team_c} = state.team;
  const {SET_TEAM_C, SET_TEAM_L, SET_TEAM_M} = useReducerActions();
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  async function fetchAllResources() {
    try {
      const [teamMemberData, teamLocalData, teamCatData] = await Promise.all([
        api.get('/api/b2b/time-vendas').catch(error => { throw new Error('Erro ao buscar membros do time') }),
        api.get('/api/b2b/time-localizacao').catch(error => { throw new Error('Erro ao buscar localização do time') }),
        api.get('/api/b2b/time-categoria').catch(error => { throw new Error('Erro ao buscar categorias do time') }),
      ]);
      dispatch(SET_TEAM_M(teamMemberData.data && teamMemberData.data));
      dispatch(SET_TEAM_L(teamLocalData.data && teamLocalData.data));
      dispatch(SET_TEAM_C(teamCatData.data && teamCatData.data));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if(team_m.length === 0 || team_l.length === 0 || team_c.length === 0){
      fetchAllResources();
    }
  }, [team_m, team_l, team_c]);

  const localizacaoOptions = [...new Set(team_l.map(local => local))];
  const categoriaOptions = [...new Set(team_c.map(categoria => categoria._id))];
  const filteredAgents = team_m.filter(item => item.location === selectedLocalizacao && item.category === selectedCategory);

  useEffect(() => {
    if (selectedCategory !== "") {
      setSelectedAgent(team_c.filter(item => item._id === selectedCategory)[0].category);
    }
    // eslint-disable-next-line
  }, [selectedCategory])

  return (
    <div className="mt-5 py-5">
      <div className="container">
        <div className="row my-4 text-center">
          <h2>Time de Vendas</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 offset-lg-2 col-sm-12 mb-3">
            <div className="card ">
              <div className="card-body">
                <h5 className="card-title">Filtrar por Região</h5>
                <select
                  className="form-select"
                  value={selectedLocalizacao}
                  onChange={(e) => setSelectedLocalizacao(e.target.value)}
                >
                  <option value="">Selecione região</option>
                  {localizacaoOptions.map((estado, key) => (
                    <option key={key} value={estado._id}>
                      {estado.estate}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 mb-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Filtrar por Categoria</h5>
                <select
                  className="form-select"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="">Selecione categoria</option>
                  {team_m.filter((item) => item.location === selectedLocalizacao).map((categoria, index) => {
                    if (categoriaOptions.includes(categoria.category)) {
                      categoriaOptions.splice(categoriaOptions.indexOf(categoria.category), 1);
                      const filteredCategory = team_c.filter(item => item._id === categoria.category).map(item => item.category)[0];
                      return (
                        <option key={index} value={categoria.category}>
                          {filteredCategory}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <h3 style={{ textAlign: 'center', margin: "1rem 0 2rem" }}>
          {
            filteredAgents.length > 0 ?
              `${selectedAgent} Agaxtur em ${team_l
                .filter((item) => item._id === selectedLocalizacao)
                .map(item => item.estate)
              }` : "Time Agaxtur pelo Brasil"
          }
        </h3>
        {
          team_m.length > 0 ?
            filteredAgents.length > 0 ? (
              <div className="row">
                {filteredAgents
                  .sort((a, b) => a.leader.localeCompare(b.leader))
                  .map((agent, index) => {
                    return (
                      <TeamCard key={index} agent={agent} index={index} team_l={team_l} emailURL={emailURL} />

                    )
                  })}
              </div>
            ) : (
              <div className="row">
                {[...team_m]
                  .sort((a, b) => a.leader.localeCompare(b.leader))
                  .map((agent, index) => {
                    return (
                      <TeamCard key={index} agent={agent} index={index} team_l={team_l} emailURL={emailURL} />
                    )
                  })}
              </div>
            )
            :
            <div className="row" id="team-card-container">
              <SkeletonTeamCard />
              <SkeletonTeamCard />
              <SkeletonTeamCard />
              <SkeletonTeamCard />
              <SkeletonTeamCard />
              <SkeletonTeamCard />
            </div>
        }
      </div>
    </div>
  );
}

const TeamCard = ({ team_l, index, agent, emailURL }) => {
  return (
    <div className="col-lg-4 col-sm-12 mb-3" id="team-card-container" key={index}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{agent.leader}</h5>
          <p className="card-text">{agent.description}</p>
          <ul className="list-unstyled">
            <li>
              <FontAwesomeIcon
                icon={solid("location-dot")}
                className="mr-2"
              />
              &nbsp; {team_l.filter((item) => item._id === agent.location).map(item => item.estate)}
            </li>
            <li>
              <FontAwesomeIcon
                icon={solid("phone")}
                className="mr-2"
              />
              &nbsp; {agent.phone}
            </li>
            <li>
              <i className="fab fa-whatsapp"></i>
              &nbsp; {agent.whatsapp}
            </li>
            <li>
              <a
                href={`${emailURL}${agent.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={solid("envelope")}
                  className="mr-2"
                />
                &nbsp; {agent.email}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const SkeletonTeamCard = () => {
  return (
    <div className="col-lg-4 col-sm-12 mb-3" >
      <div className="card">
        <div className="card-body">
          <h5 className="card-title" id="skeleton-title">23123123</h5>
          <p className="card-text" id="skeleton-title">456456</p>
          <ul className="list-unstyled">
            <li id="skeleton-title">
              <FontAwesomeIcon
                icon={solid("location-dot")}
                className="mr-2"
              />
              &nbsp; 123213213123
            </li>
            <li id="skeleton-title">
              <FontAwesomeIcon
                icon={solid("phone")}
                className="mr-2"
              />
              &nbsp; 321321321
            </li>
            <li id="skeleton-title">
              <i className="fab fa-whatsapp"></i>
              &nbsp; 123123123
            </li>
            <li id="skeleton-title">
              &nbsp; 1553135
            </li>
          </ul>
        </div>
      </div>
    </div >
  )
}