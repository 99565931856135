import React, { useEffect, useState } from "react";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from 'react-router-dom';

export default function HorarioPlantao() {
  const [horarios, setHorarios] = useState([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/b2b/horarios-plantao`
        );
        setHorarios(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);

  return (
    <section
      className="bg-primary text-center"
      style={{ marginTop: 50, color: "#FFF", fontSize: 18}}
    >
      <div className="container" >
        <p style={{ fontSize: 48 }}>Horários e Plantão</p>
        {horarios.length > 0 && horarios[0].isVisible === 'Exibir banner' ?
          <LazyLoadImage
            className="img-fluid"
            src={`https://agaxtur-uploads.s3.amazonaws.com/${horarios[0].imageUrl}`}
            alt="horarios e plantao imagem"
            style={{
              width: '100%',
              objectFit: 'contain'
            }}
          /> :
          <>
            <p>Parece que não há nada por aqui...</p>
            <button
            onClick={() => navigate('/')} 
            id="btn-horarios-plantao"  
            className="btn btn-primary">Navegar para Home</button>
          </>
        }
      </div>
    </section>
  );
};
