import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export default function NavigationMobile({ variant, icon, destinos, subCategorias, onLinkPress }) {
    const [showOptions, setshowOptions] = useState(false)

    // onLinkPress is for collapse navbar button into mobile layout
    const options = {
        container: {
            display: 'block',
            margin: '.2rem 0'
        },
        content: {
            display: !showOptions ? 'none' : 'block',
            padding: 0,
            margin: '.8rem 0'
        },
        textStyles: {
            color: '#555',
            listStyle: 'none'
        },
    }

    const handleClick = () => {
        setshowOptions(!showOptions)
    }

    useEffect(() => {
        setshowOptions(false)
    }, [])

    return (
        <>
            {variant === 'brasil' ?
                <li role="presentation" className="nav-item dropdown" style={options.container} >
                    <a
                        className="dropdown-toggle mobile-section-title nav-item-mobile-text"
                        aria-current="page"
                        href="javascript:void(0)"
                        id="navbarDropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={() => handleClick()}
                    >
                        <img src={icon} alt='icon' />
                        <span className="nav-link-text nav-item-mobile-text" style={{ marginLeft: 3 }}>Destinos Brasil</span>
                    </a>
                    <ul className="dropdown-mobile" aria-labelledby="navbarDropdownMenuLink" style={options.content}>
                        {subCategorias
                            .sort((a, b) => a.title.localeCompare(b.title))
                            .filter(sub => sub.categoria === '635ac080cfec79385a6cd307' && sub.isVisible === "Exibir no menu")
                            .map(sub => {
                                return (
                                    <li role="presentation" className="dropdown destinos-categoria" key={sub._id} style={options.textStyles}>
                                        {/* eslint-disable-next-line */}
                                        <a className={`dropdown-toggle-mobile  nav-item-mobile-text`} data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" style={options.textStyles}>
                                            {sub.title}<span className="caret"></span>
                                        </a>
                                        <ul className="dropdown-menu mobile-item">
                                            {destinos
                                                .sort((a, b) => a.title.localeCompare(b.title))
                                                .filter(destino => destino.submenu === sub._id)
                                                .map(destino => {
                                                    return (
                                                        <Link
                                                            key={destino._id}
                                                            className="dropdown-item mobile-item-link"
                                                            onClick={onLinkPress}
                                                            to={destino.selectDirection === 'SLUG' ?
                                                                `/destinos/${destino.slug}` : destino.pageUrl
                                                            }
                                                            target={destino.selectDirection !== 'SLUG' ? '_blank' : '_self'}
                                                        >
                                                            {destino.title}
                                                        </Link>
                                                    )
                                                })}
                                        </ul>
                                    </li>
                                )
                            })}
                        {destinos
                            .filter(destino => destino.menu === '635ac080cfec79385a6cd307' && !destino.submenu)
                            .map(destino => {
                                return (
                                    <li key={destino._id} className="nav-item dropdown" style={options.textStyles}>
                                        <Link className="dropdown-item nav-item-mobile-text" to={`/destinos/${destino.slug}`} onClick={onLinkPress}>{destino.title}</Link>
                                    </li>
                                )
                            })}
                    </ul>
                </li>
                : variant === 'mundo' ?
                    <li role="presentation" className="nav-item dropdown" style={options.container} >
                        <a
                            className="dropdown-toggle mobile-section-title nav-item-mobile-text"
                            aria-current="page"
                            href="javascript:void(0)"
                            id="navbarDropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={() => handleClick()}
                        >
                            {variant === 'mundo' && icon}
                            <span className="nav-link-text nav-item-mobile-text" style={{ marginLeft: 3 }}>Destinos Mundo</span>
                        </a>
                        <ul className="dropdown-mobile" aria-labelledby="navbarDropdownMenuLink" style={options.content}>
                            {subCategorias
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .filter(sub => sub.categoria === '635ac088cfec79385a6cd30b' && sub.isVisible === "Exibir no menu")
                                .map(sub => {
                                    return (
                                        <li role="presentation" className="dropdown destinos-categoria" key={sub._id} style={options.textStyles}>
                                            {/* eslint-disable-next-line */}
                                            <a className={`dropdown-toggle-mobile nav-item-mobile-text`} data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" style={options.textStyles}>
                                                {sub.title}<span className="caret"></span>
                                            </a>
                                            <ul className="dropdown-menu mobile-item">
                                                {destinos
                                                    .sort((a, b) => a.title.localeCompare(b.title))
                                                    .filter(destino => destino.submenu === sub._id)
                                                    .map(destino => {
                                                        return (
                                                            <Link
                                                                key={destino._id}
                                                                className="dropdown-item mobile-item-link"
                                                                onClick={onLinkPress}
                                                                to={destino.selectDirection === 'SLUG' ?
                                                                    `/destinos/${destino.slug}` : destino.pageUrl
                                                                }
                                                                target={destino.selectDirection !== 'SLUG' ? '_blank' : '_self'}
                                                            >
                                                                {destino.title}
                                                            </Link>
                                                        )
                                                    })}
                                            </ul>
                                        </li>
                                    )
                                })}
                            {destinos
                                .filter(destino => destino.menu === '635ac088cfec79385a6cd30b' && destino.submenu == null)
                                .map(destino => {
                                    return (
                                        <li key={destino._id} className="nav-item dropdown" style={options.textStyles}>
                                            <Link className="dropdown-item nav-item-mobile-text" to={`/destinos/${destino.slug}`} onClick={onLinkPress}>{destino.title}</Link>
                                        </li>
                                    )
                                })}
                        </ul>
                    </li> : null
            }
        </>
    )
}
