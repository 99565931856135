import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import slugify from 'slugify';

import './styles.css';

const PacoteCard = ({ pacote, showButton, cruzeiro, uniqueLink, circuito }) => {
  // const buttonVisible = showButton !== undefined ? showButton : false;
  const navigateToLink = (link) => {
    window.location.assign(link);
  };
  const slug = slugify(pacote.title, { lower: true });

  const packageLink = circuito ? `/circuitos/${slug}/${pacote._id}` :
    cruzeiro ? `/cruzeiros/${pacote.slug}` :
      pacote.destino ? `/${pacote.destino.slug}/${slug}/${pacote._id}` :
        null

  return (
    <div onClick={() => !packageLink && navigateToLink(uniqueLink)} key={pacote.title} className="col-6 col-md-3 col-lg-3 mb-2"
      id="geral-pacote-card-container" style={{ cursor: 'pointer' }}>
      <a href={packageLink}>
        <div className="pacote-box h-100">
          <div className="pacote-img" id="skeleton-image">
            <LazyLoadImage
              className="img-fluid"
              alt={""}
              id="skeleton-image"
              src={`https://agaxtur-uploads.s3.amazonaws.com/${pacote.thumbnail ? pacote.thumbnail : pacote.imageUrl[0]}`}
            />
          </div>
          <div className="pacote-info p-2">
            {pacote.noites ?
              <span className="badge bg-primary fs--1 me-2" style={{ display: 'flex', gap: 5, whiteSpace: 'nowrap' }}>
                <i className="fas fa-calendar" ></i>
                {pacote.noites} noites
              </span> : null
            }

          </div>
          <div className="pacote-detalhes p-3" >
            <h2 className="fs-1 fs-md-2">{pacote.title}</h2>
            {!cruzeiro ?
              <div
                className="pacote-descricao"
                dangerouslySetInnerHTML={{ __html: `${pacote.description}` }}>
              </div>
              : null}
            <div>
              {pacote.price ?
                <div>
                  <small className="pacote-texto-valor">{pacote.textoValor}</small> <br />
                  <h3>{pacote.price}</h3>
                </div>
                : null
              }
              {pacote.valorTotal ?
                <div className="pactote-texto-total">
                  {pacote.valorTotal}
                </div>
                : null
              }
              {pacote.departure !== "" ?
                <p style={{ margin: '2px 0px 0px 0px', color: '#222', padding: 0, fontSize: 11 }}>
                  {pacote.departure}
                </p>
                : null
              }
              {/* Após solicitão o botão foi retirado, todavia deixo o código aqui */}
              {/* {buttonVisible ?
                <span className="btn-large btn btn-secondary mt-3" >
                  {pacote.textoBotao}
                </span>
                : null
              } */}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default PacoteCard;