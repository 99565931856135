import { configureStore } from "@reduxjs/toolkit";
import travelReducer from "../reducer/travel/travelReducer";
import cruisesReducer from "../reducer/cruises/cruisesReducer";
import extrasReducer from "../reducer/extras/extrasReducer";
import teamReducer from "../reducer/team/teamReducer";

export const store = configureStore({
    reducer: {
        travel: travelReducer,
        cruises: cruisesReducer,
        extras: extrasReducer,
        team: teamReducer,
    },
});
