import React from "react";
import FormularioSharp from "../components/FormularioSharp";

import img1 from "../images/destinos/pratagy.jpg";
import img2 from "../images/destinos/aracaju.jpg";
import img3 from "../images/destinos/maragogi.jpg";
import img4 from "../images/destinos/cumbuco.jpg";
import img5 from "../images/destinos/parnaiba.jpg";
import img6 from "../images/destinos/caldas-novas.jpg";
import img7 from "../images/destinos/jalapao.jpg";
import img8 from "../images/destinos/alter-do-chao.jpg";
import img9 from "../images/destinos/balneario-camboriu.jpg";

import background from "../images/destinos/maceio.jpg";

const Destino = "Brasil";

class Brasil extends React.Component {
  componentDidMount() {
    document.title = Destino + " - Agaxtur Viagens";
  }
  render() {
    return (
      <>
        <div className="destinos">
          <header
            className="text-center"
            style={{ backgroundImage: `url(${background})` }}
          >
            <h1>{Destino}</h1>
            <h3>
              <span className="badge bg-secondary">
                Garanta as melhores ofertas!
              </span>
            </h3>
            <a href="#pacotes" className="btn btn-primary">
              Ver Ofertas
            </a>
          </header>
          <div className="container">
            <div id="pacotes">
              <center>
                <h2 className="my-5">
                  Ofertas <span className="azul-claro">Imperdíveis</span>
                </h2>
              </center>
              <div className="row mb-4">
                <div className="col-lg-4 col-sm-12">
                  <div className="card card-span h-100 text-white">
                    <img src={img1} className="card-img-top" alt={Destino} />
                    <h2 className="card-header text-truncate">Maceió </h2>
                    <div className="card-body">
                      <span className="badge bg-primary">
                        Pratagy Beach Resort
                      </span>
                      <span className="badge bg-secondary">
                        <i className="fas fa-calendar me-1"></i> 7 noites
                      </span>
                      <div className="card-body ps-0">
                        <p className="text-900">
                          Hospedagem all inclusive, 05 dias de acesso no Acqua
                          Park, traslados, seguro viagem.
                        </p>
                        <a
                          href="https://agaxtur.softur.com.br/sala/Agaxtur?c=B0V2YGDQ&v=S&tipo=VIDEO"
                          className="btn-large btn btn-secondary"
                        >
                          Fale com um consultor
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card card-span h-100 text-white">
                    <img src={img2} className="card-img-top" alt={Destino} />
                    <h2 className="card-header text-truncate">
                      Cânions de Xingó
                    </h2>
                    <div className="card-body">
                      <span className="badge bg-primary">
                        Aracaju com Canions de Xingó
                      </span>
                      <span className="badge bg-secondary">
                        <i className="fas fa-calendar me-1"></i> 7 noites
                      </span>
                      <div className="card-body ps-0">
                        <p className="text-900">
                          Hospedagem no hotel Aquários Praia com café da manhã
                          em Aracaju, no Xingó Parque Hotel com meia pensão em
                          Canindé de São Francisco, passeios, traslados e seguro
                          viagem.
                        </p>
                        <a
                          href="https://agaxtur.softur.com.br/sala/Agaxtur?c=G0FMGW5Q&v=S&tipo=VIDEO"
                          className="btn-large btn btn-secondary"
                        >
                          Fale com um consultor
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card card-span h-100 text-white">
                    <img src={img3} className="card-img-top" alt={Destino} />
                    <h2 className="card-header text-truncate">Maragogi</h2>
                    <div className="card-body">
                      <span className="badge bg-primary">
                        Grand Oca Maragogi
                      </span>
                      <span className="badge bg-secondary">
                        <i className="fas fa-calendar me-1"></i> 7 noites
                      </span>
                      <div className="card-body ps-0">
                        <p className="text-900">
                          Hospedagem all inclusive, traslados, seguro viagem.
                        </p>
                        <a
                          href="https://agaxtur.softur.com.br/sala/Agaxtur?c=NX04J7SG&v=S&tipo=VIDEO"
                          className="btn-large btn btn-secondary"
                        >
                          Fale com um consultor
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-4 col-sm-12">
                  <div className="card card-span h-100 text-white">
                    <img src={img4} className="card-img-top" alt={Destino} />
                    <h2 className="card-header text-truncate">Cumbuco</h2>
                    <div className="card-body">
                      <span className="badge bg-primary">
                        Carmel Cumbuco Resort
                      </span>
                      <span className="badge bg-secondary">
                        <i className="fas fa-calendar me-1"></i> 7 noites
                      </span>
                      <div className="card-body ps-0">
                        <p className="text-900">
                          Hospedagem com café da manhã, traslados, seguro
                          viagem.
                        </p>
                        <a
                          href="https://agaxtur.softur.com.br/sala/Agaxtur?c=N0GX2Y7Z&v=S&tipo=VIDEO"
                          className="btn-large btn btn-secondary"
                        >
                          Fale com um consultor
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card card-span h-100 text-white">
                    <img src={img5} className="card-img-top" alt={Destino} />
                    <h2 className="card-header text-truncate">
                      Rota das Emoções
                    </h2>
                    <div className="card-body">
                      <span className="badge bg-primary">Rota das Emoções</span>
                      <span className="badge bg-secondary">
                        <i className="fas fa-calendar me-1"></i> 6 noites
                      </span>
                      <div className="card-body ps-0">
                        <p className="text-900">
                          Hospedagem em Barreirinhas, Parnaíba e Jericoacoara
                          com café da manhça, passeios, traslados, seguro
                          viagem.
                        </p>
                        <a
                          href="https://agaxtur.softur.com.br/sala/Agaxtur?c=NFBMK718&v=S&tipo=VIDEO"
                          className="btn-large btn btn-secondary"
                        >
                          Fale com um consultor
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card card-span h-100 text-white">
                    <img src={img6} className="card-img-top" alt={Destino} />
                    <h2 className="card-header text-truncate">
                      Praias do Lago Eco Resort
                    </h2>
                    <div className="card-body">
                      <span className="badge bg-primary">{Destino}</span>
                      <span className="badge bg-secondary">
                        <i className="fas fa-calendar me-1"></i> 7 noites
                      </span>
                      <div className="card-body ps-0">
                        <p className="text-900">
                          Hospedagem com meia pensão, acesso aos parques
                          aquáticos Water Park, Clube Privé e Náutico Praia
                          Clube, traslados, seguro viagem.
                        </p>
                        <a
                          href="https://agaxtur.softur.com.br/sala/Agaxtur?c=6NRLXCKL&v=S&tipo=VIDEO"
                          className="btn-large btn btn-secondary"
                        >
                          Fale com um consultor
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-4 col-sm-12">
                  <div className="card card-span h-100 text-white">
                    <img src={img7} className="card-img-top" alt={Destino} />
                    <h2 className="card-header text-truncate">Jalapão</h2>
                    <div className="card-body">
                      <span className="badge bg-primary">
                        Jalapão Essencial
                      </span>
                      <span className="badge bg-secondary">
                        <i className="fas fa-calendar me-1"></i> 5 noites
                      </span>
                      <div className="card-body ps-0">
                        <p className="text-900">
                          Hospedagem com café da manhã em Palmas, e no Safari
                          Camp Korubo com pensão completa, passeios aos
                          principais atrativos, traslados, seguro viagem.
                        </p>
                        <a
                          href="https://agaxtur.softur.com.br/sala/Agaxtur?c=P6CDV83H&v=S&tipo=VIDEO"
                          className="btn-large btn btn-secondary"
                        >
                          Fale com um consultor
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card card-span h-100 text-white">
                    <img src={img8} className="card-img-top" alt={Destino} />
                    <h2 className="card-header text-truncate">Alter do Chão</h2>
                    <div className="card-body">
                      <span className="badge bg-primary">
                        Alter do Chão Experiência
                      </span>
                      <span className="badge bg-secondary">
                        <i className="fas fa-calendar me-1"></i> 4 noites
                      </span>
                      <div className="card-body ps-0">
                        <p className="text-900">
                          Hospedagem com café da manhã, passeios aos principais
                          atrativos, traslados, seguro viagem.
                        </p>
                        <a
                          href="https://agaxtur.softur.com.br/sala/Agaxtur?c=0CC16539&v=S&tipo=VIDEO"
                          className="btn-large btn btn-secondary"
                        >
                          Fale com um consultor
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card card-span h-100 text-white">
                    <img src={img9} className="card-img-top" alt={Destino} />
                    <h2 className="card-header text-truncate">
                      Balneário Camboriu
                    </h2>
                    <div className="card-body">
                      <span className="badge bg-primary">
                        Balneário Camboriu
                      </span>
                      <span className="badge bg-secondary">
                        <i className="fas fa-calendar me-1"></i> 7 noites
                      </span>
                      <div className="card-body ps-0">
                        <p className="text-900">
                          Hospedagem no hotel Flat Concept com café da manhã,
                          passeio Rota Germânica, traslados, seguro viagem.
                        </p>
                        <a
                          href="https://agaxtur.softur.com.br/sala/Agaxtur?c=GFWV9VQT&v=S&tipo=VIDEO"
                          className="btn-large btn btn-secondary"
                        >
                          Fale com um consultor
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormularioSharp />
      </>
    );
  }
}
export default Brasil;
