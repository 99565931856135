import React, { useEffect, useState } from "react";
import FormularioSharp from "../components/FormularioSharp";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useFetch } from "../hooks/useFetch";
import { useParams } from "react-router-dom";

const TEXT_CALL_TO_ACTION = "CONFIRA OS ROTEIROS!";
const TEXT_MORE = "SAIBA MAIS!";

const Cruzeiros = () => {
  const { slug } = useParams();
  const [cruzeiros, setCruzeiros] = useState({});
  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/cruzeiros/${slug}`
        );
        setCruzeiros(data[0]);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, [slug]);

  const { data: Cruzeiro } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/cruzeiros/`
  );

  return (
    <>
      <div className="destinos">
        <header
          className="text-center"
          style={{
            backgroundImage: `url(https://agaxtur-uploads.s3.amazonaws.com/${cruzeiros.imageUrl})`,
          }}
        >
          <h1>{cruzeiros.title}</h1>
          <h3>
            <span className="badge bg-secondary">{cruzeiros.description}</span>
          </h3>
        </header>
        <div className="container">
          <div id="pacotes" className="py-3 mt-4">
            <h2 className="mb-4 text-center">
              <span className="azul-claro">Confira</span>
            </h2>
            <div className="row mb-4">
              {Cruzeiro.filter(
                (cruzeiro) => cruzeiro.menu === cruzeiros._id
              ).map((cruzeiro) => {
                return (
                  <div className="col-lg-4 col-sm-12 mb-4">
                    <div className="card card-span h-100 text-white">
                      <LazyLoadImage
                        src={`https://agaxtur-uploads.s3.amazonaws.com/${cruzeiro.imageUrl}`}
                        className="card-img-top"
                        alt={cruzeiro.title}
                      />
                      <h2 className="card-header text-truncate">
                        {cruzeiro.title}
                      </h2>
                      <div className="card-body">
                        <span className="badge bg-primary">
                          {cruzeiros.title}
                        </span>
                        {cruzeiro.price ? (
                          <span className="badge bg-secondary">
                            <i className="fas fa-calendar me-1"></i>{" "}
                            {cruzeiro.noites} noites
                          </span>
                        ) : null}
                        <div className="card-body ps-0">
                          <p className="text-900">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${cruzeiro.description}`,
                              }}
                            ></div>
                          </p>
                          {cruzeiro.price ? (
                            <div>
                              <span className="text-900">A partir de:</span>{" "}
                              <h1 className="mb-3 text-primary fw-bolder fs-4">
                                {cruzeiro.price}
                              </h1>
                            </div>
                          ) : null}
                          {cruzeiros._id === "635d6f7c9fd077fef0414e45" ||
                          cruzeiro.title === "Internacionais" ||
                          cruzeiro.title === "América do Sul" ? (
                            <a
                              href={"https://agaxtur.travellink.com.br/"}
                              className="btn-large btn btn-secondary"
                            >
                              {TEXT_CALL_TO_ACTION}
                            </a>
                          ) : (
                            <a
                              href={"https://agaxtur.travellink.com.br/"}
                              className="btn-large btn btn-secondary"
                            >
                              {TEXT_MORE}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <FormularioSharp />
    </>
  );
};

export default Cruzeiros;
