import React, { useEffect } from "react";
import Folheto from "../components/Folheto";
import FormularioSharp from "../components/FormularioSharp";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useReducerActions from "../reducer";

const Folheteria = () => {
  const state = useSelector((state) => state);
  const { flyer } = state.extras;
  const dispatch = useDispatch();
  const { SET_FLYER } = useReducerActions();

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/folheteria`
        );
        dispatch(SET_FLYER(data));
      } catch (err) {
        console.error(err);
      }
    };
    if(!flyer.length > 0){
      fetch();
    }
    // eslint-disable-next-line
  }, []);

  document.title = "Folheteria - Agaxtur Viagens";

  return (
    <>
      <section
        className="text-center mt-4"
        style={{ color: "#FFF", fontSize: 18 }}
      >
        <div className="container">
          <div className="row my-4 text-center">
            <h2 style={{ color: "#001073"}}>Folheteria</h2>
          </div>

          <p className="mb-2">Faça download da nossa folheteria: </p>

          <div className="row justify-content-between" style={{ rowGap: 30 }}>
            {flyer.map((folheto, i) => (
              <Folheto className="col" key={i} data={folheto} />
            ))}
          </div>
        </div>
      </section>
      <FormularioSharp />
    </>
  );
};

export default Folheteria;
