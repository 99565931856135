import React, { useEffect } from "react";
import Ofertas from "../components/home/Ofertas";
import Nacionais from "../components/home/Nacionais";
import CruzeirosHome from "../components/home/Cruzeiros";
import Exoticos from "../components/home/Exoticos";
import Internacionais from "../components/home/Internacionais";
import Header from "../components/home/Header/index.jsx";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import useReducerActions from './../reducer';

function Home() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { SET_BANNER } = useReducerActions();
  const { travel_p } = state.travel;
  const { banner } = state.extras;

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  async function fetchAllResources() {
    try {
      const [ bannerData ] = await Promise.all([
        api.get('/api/banners').catch(error => { throw new Error('Erro ao buscar banners') }),
      ]);
      dispatch(SET_BANNER(bannerData.data && bannerData.data));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if(!banner){
      fetchAllResources();
    }
    document.title = "Home - Agaxtur Viagens"
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Home">
      <Header />
      <Ofertas data={travel_p} />
      {
        travel_p.length > 0 &&
        <>
          <CruzeirosHome />
          <Nacionais data={travel_p} />
          <Internacionais data={travel_p} />
          <Exoticos data={travel_p} />
        </>
      }
    </div>
  );
}
export default Home;
