import React from "react";
import background from "../images/gallery/passagens2.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Passagens = ({ paginaId }) => {
  const [data, setData] = useState([]);
  const state = useSelector((state) => state);
  const { pages_t } = state.extras;
  const navigate = useNavigate();

  useEffect(() => {
    if (!pages_t.length > 0) {
      navigate('/');
    } else {
      pages_t
        .filter((pagina) => pagina._id === paginaId)
        .map((data) => setData(data));
    }
    // eslint-disable-next-line
  }, []);

  document.title = data.title + " - Agaxtur Viagens";

  return (
    <main>
      <div
        className="destinos bg-primary mt-5 py-5 text-center text-light"
        style={{ backgroundImage: `url(${background})` }}
      >
        <h1 className="text-light mt-5">{data.title}</h1>
        <p>
          <span className="text-light">
            {data.subtitulo}
          </span>
        </p>
        <a
          href={"https://agaxtur.travellink.com.br/"}
          className="btn btn-secondary btn-large"
        >
          {data.textoBotao}
        </a>
      </div>

      <div className="bg-light py-5">
        <div className="container">
          <div className="row pt-5 pb-4">
            <div className="col-lg-6">
              <div dangerouslySetInnerHTML={{ __html: data.conteudo }} />
            </div>
            <div className="col-lg-6 text-center">
              <img src={`https://agaxtur-uploads.s3.amazonaws.com/${data.imageUrl}`} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Passagens;
