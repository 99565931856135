import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PacoteCard from "./PacoteCard/index.jsx";

import SkeletonCard from "../SkeletonCard/index.jsx";
import { useSelector } from "react-redux";

const CruzeirosHome = () => {
  const state = useSelector((state) => state);
  const { cruises_p, cruises_c } = state.cruises;
  const { texts } = state.extras;

  return (
    <>
      {cruises_c.length > 0 && (
        <section className="py-3 overflow-hidden grade-imagens" id="populares">
          <div className="container bg-offcanvas-gray-right">
            <div className="row gx-2 mb-2">
              <div className="col-12 col-md-12 col-lg-3 mb-3">
                <div className="gradient-base-dark-blue gradient-row h-100 d-flex align-items-center">
                  <div className="row g-0 ">
                    <div className="col-12">
                      <div className="p-lg-4 px-2 py-3 px-md-3 text-light">
                        <h2 className="mb-2 text-light"> <FontAwesomeIcon icon={solid("ship")} size="1x" style={{marginRight: 10}}/>
                          {texts && texts.filter((titulo) => titulo._id === "644a9880f3291aa9d5a21fb0")[0].title}
                        </h2>
                        <p>{texts && texts.filter((titulo) => titulo._id === "644a9880f3291aa9d5a21fb0")[0].texto}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                cruises_p ?
                  <>
                    {cruises_c.filter(categoria => categoria.featured === true).map(categoria => (
                      // <a href={`/cruzeiros/${categoria.slug}`} key={categoria._id} >
                      <PacoteCard pacote={categoria} key={categoria._id} cruzeiro={true} />
                    ))}
                    {cruises_p.filter((pacote) => pacote.featured === true).map((pacote) => (
                      <PacoteCard pacote={pacote} key={pacote._id} uniqueLink={pacote.url} />
                    ))}
                  </>
                  :
                  <>
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                  </>
              }
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default CruzeirosHome;
